import axios from "../instance";

export default {
    getAll: () => {
        return axios.api.get("/transactionNews").then((response) => response.data);
    },
    getOne: (id) => {
        return axios.api.get(`/transactionNews/${id}`).then((response) => response.data);
    },
    search: (body) => {
        return axios.api.post("/transactionNews/search", body).then((response) => response.data);
    },
    create: (body) => {
        return axios.api.post("/transactionNews", body).then((response) => response.data);
    },
    update: (id, body) => {
        return axios.api.put(`/transactionNews/${id}`, body).then((response) => response.data);
    },
    delete: (id) => {
        return axios.api.delete(`/transactionNews/${id}`).then((response) => response.data);
    }
};
