<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        สรุปการขาย
      </h3>
      <div class="card-toolbar">
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-success btn-hover-scale me-2"
          style="font-size: 14px; font-weight: bold"
          @click="gotoImportExcel()"
        >
          นำข้อมูลเข้าจาก Excel
        </button> -->
        <!-- <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          + เพิ่มใบขายส่ง
        </button> -->
      </div>
    </div>
    <div class="card-title row ms-5 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <a-date-picker
          class="form-control ant-date"
          :locale="locale"
          v-model:value="date"
          format="DD/MM/YYYY"
          inputReadOnly
        />
      </div>
      <div class="col-sm-3 mb-3">
        <input
          v-model="searchInput"
          placeholder="ค้นหาจาก เลขที่เอกสาร / รหัสผู้ซื้อ"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          ค้นหา
        </button>
      </div>
    </div>

    <div class="card-body" style="padding-top: 3px; padding-bottom: 15px">
      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              style="width: 100%"
              class="
                fw-bold
                fs-5
                text-gray-800
                border-bottom-2 border-gray-200
                mw-200px
              "
            >
              <th>เลขที่เอกสาร</th>
              <th>รหัสผู้ซื้อ</th>
              <th>ผู้ซื้อ</th>
              <th>วัน-เวลา</th>
              <th>ราคา</th>
              <th>ส่วนลด</th>
              <th>ภาษี</th>
              <th>สุทธิ</th>
              <!-- <th>status</th> -->
              <th style="text-align: center">reprint</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="9">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <td>{{ item.point_of_sale_no ? item.point_of_sale_no : "" }}</td>
              <td>
                {{ item.customer ? item.customer.code : "" }}
              </td>
              <td>
                <!-- {{ item.customer ? item.customer.customer_name : "" }} -->
                {{ item.customer.name }}
              </td>
              <td>
                {{ setDateThLocale(item.createdAt) }}
              </td>
              <td>{{ item.total_price_before_vat }} ฿</td>
              <!-- <td>{{ item.discount }}</td> -->
              <td>{{ item.discount ? item.discount : 0 }} %</td>
              <!-- <td>
                {{
                  parseInt(
                    item.total_price_include_vat
                      ? item.total_price_include_vat
                      : 0
                  ) -
                  parseInt(
                    item.total_price_before_vat
                      ? item.total_price_before_vat
                      : 0
                  )
                }}
              </td> -->
              <td>{{ item.vat }} ฿</td>
              <td>{{ item.final_price }} ฿</td>

              <td>
                <div class="row">
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        m-0
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-eye-fill"></i>
                    </button>
                  </div>
                  <div class="col-sm-4">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        m-0
                        p-0
                      "
                    >
                      <i class="bi bi-printer-fill"></i>
                    </button>
                  </div>

                  <div class="col-sm-4">
                    <button
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        m-0
                        p-0
                      "
                      @click="handleCancelOrder(item)"
                      @mouseenter="handleHoverB()"
                      style="color: red; cursor: pointer"
                    >
                      <i class="bi bi-x-circle"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <h4 class="mt-3">
        ยอดรวม : <b>{{ total }}</b> บาท
      </h4>
    </div>

    <!-- <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    /> -->

    <DialogCancelOrder
      :dialogCancelOrder="dialogCancelOrder"
      @submitCancelOrder="submitCancelOrder"
      @closeDialogCancelOrder="closeDialogCancelOrder"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import saleApi from "@/api/sale/";
import transactionApi from "@/api/transaction/";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import DialogCancelOrder from "../components/dialogCancelOrder.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import locale from "ant-design-vue/es/date-picker/locale/th_TH";
import moment from "moment";
import dayjs, { Dayjs } from "dayjs";

export default {
  components: {
    DialogConfirmByPass,
    DialogCancelOrder,
    Pagination,
    Loader,
  },
  setup() {
    document.title = "bem-document-demo สรุปขายปลีกประจำวัน";
    return {
      locale,
    };
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    dialogConfirmByPass: false,
    dialogCancelOrder: false,

    date: dayjs(new Date()),

    total: "",
    tableItems: [],
    itemApprove: {},

    hoverA: false,
    hoverB: false,

    searchInput: "",
    dateSearch: "",
    allData: [],
  }),

  created() {
    // this.search();
    this.getAll();
  },

  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      // let companyId = localStorage.getItem("companyId");
      try {
        responseData = await transactionApi.transaction.getAll();
      } catch (error) {
        console.log(error);
      }
      console.log({ responseData });
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.loading = false;
        this.sumTotal(this.dataItemsShow);
        // if (method === 1) {
        //   await this.$router.push({
        //     query: {
        //       page: this.$route.query.page,
        //       get: 1,
        //     },
        //   });
        // }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },

    sumTotal(items) {
      let sum = items.reduce(
        (sum, data) => sum + parseFloat(data.final_price),
        0
      );
      sum = sum.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
      this.total = sum;
    },

    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      this.$router.push("/sale/SA2-1/new");
    },
    goToEdit(id) {
      this.$router.push({
        path: "/sale/SA5",
        query: {
          id: id,
        },
      });
    },
    goToDelete() {},

    handleHoverA() {
      this.hoverA = true;
      this.hoverB = false;
    },
    handleHoverB() {
      this.hoverB = true;
      this.hoverA = false;
    },

    handleApprove() {
      this.dialogConfirmByPass = true;
    },

    handleCancelOrder(item) {
      this.itemApprove = { ...item };
      this.dialogCancelOrder = true;
    },

    submitConfirmPass() {
      this.isApprove = true;
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.isApprove = false;
      this.dialogConfirmByPass = false;
    },

    submitCancelOrder(isApprove) {
      this.statusApprove(isApprove, "cancel");
      this.dialogCancelOrder = false;
    },

    closeDialogCancelOrder() {
      this.dialogCancelOrder = false;
    },

    formatDate(date) {
      const newDate = moment(date.substring(0, 10)).format("DD/MM/YYYY");
      const newTime = moment(date).format("LTS");
      if (date) {
        const oldDate = `${newDate} - ${newTime}`;

        return oldDate;
      } else {
        return "-";
      }
    },

    async statusApprove(isApprove, method) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
        is_cancel: method === "cancel" ? "1" : "0",
      };

      // updateResponse = await saleApi.pos.delete(id, this.itemApprove);

      console.log(this.itemApprove);
      updateResponse = await saleApi.pos.delete(id);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          this.updateProduct();
          Swal.fire({
            icon: `${method === "cancel" ? "error" : "success"}`,
            title: `${
              method === "cancel" ? "ยกเลิกรายการสำเร็จ" : "อนุมัติสำเร็จ"
            }`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.loadingUpdate = false;
            this.search();
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.search();
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },

    // update item
    async updateProduct() {
      let countArr = 0;
      for (const element of this.itemApprove.pointOfSaleItems) {
        countArr += 1;
        await this.getOneProduct(element.product.id, element.item_amt);
      }
      // if (
      //   countArr === this.form.products.length &&
      //   this.statusAllProcess === 1
      // ) {
      //   this.$router.push({
      //     path: "/sale/SA5",
      //     query: {},
      //   });
      // }
    },
    async getOneProduct(id, item_amt) {
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.product.getOne(id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.updateProductAmountRecieve(item_amt, getOneResponse.data);
      }
    },
    async updateProductAmountRecieve(item_amt, item) {
      let updateResponse = [];
      let sumQty = parseFloat(item.product_bring_qty) + parseFloat(item_amt);

      const body = {
        ...item,
        product_bring_qty: sumQty,
      };

      updateResponse = await whApi.product.update(item.id, body);
      if (updateResponse.response_status !== "SUCCESS") {
        this.statusAllProcess *= 0;

        Swal.fire({
          icon: "error",
          html: `ชำระค่าสินค้าไม่สำเร็จ !`,
          timer: 4000,
        });
      } else {
        this.statusAllProcess *= 1;
      }
    },
    // update item

    setDateThLocale(dateVal) {
      moment.locale("th");
      return moment(dateVal).format("DD/MM/YYYY - HH:mm:ss น.");
    },

    async search() {
      const newDate = this.date
        ? moment(this.date.$d).format("YYYY-MM-DD")
        : "";

      this.loading = true;

      const responseSearch = await transactionApi.transaction.search({
        search: this.searchInput,
        date: newDate,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
        this.sumTotal(this.dataItemsShow);
      }
    },
  },
};
</script>
<style scoped src="@/assets/custom-outside.css"></style>
